import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { NavigationService } from './services/navigation.service';
import { NotificationService } from './services/notification.service';
import { SharedDataService } from './services/shared-data.service';
import { AppLoadService } from './services/app-load.service';
import { DialogService } from './services/dialog.service';
import { AuthService } from './services/auth.service';
import { RouterModule } from '@angular/router';
import { DataService } from './services/data.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ListService } from './services/list.service';

// Restore shared data from localstorage before app loads
export function restoreSharedData(appLoadService: AppLoadService) {
    return () => appLoadService.restoreSharedData();
}

@NgModule({
    declarations: [

    ],
    imports: [
        FlexLayoutModule,
        MaterialModule,
        RouterModule
    ],
    exports: [
        FlexLayoutModule,
        MaterialModule,
        RouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AppLoadService,
        NavigationService,
        NotificationService,
        SharedDataService,
        DialogService,
        AuthService,
        ListService,
        DataService,
        { provide: APP_INITIALIZER, useFactory: restoreSharedData, deps: [AppLoadService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ]
})
export class SharedModule { }