import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute) { }

  back() {
    return this.location.back();
  }

  forward() {
    return this.location.forward();
  }

  navigateTo(routeName: any, isRelative: Boolean = false) {
    if (isRelative) {
      this.router.navigate(routeName, { relativeTo: this.route })
    } else {
      this.router.navigate(routeName);
    }
  }

  getParam() {
    return this.router.url.split('/')[2];
  }

}
