import { Injectable } from '@angular/core';
import { DataService } from './data.service';


export interface Config {
    self: any; // Reference to the component or service using this configuration
    listName: string; // Name of the list property in the component's state
    url: string; // API endpoint for fetching data
    searchUrl?: string; // Optional API endpoint for search functionality
    callback?: any; // Optional callback function to execute after data is fetched
    skip?: number; // Number of records to skip for pagination
    limit?: number; // Number of records to fetch per request
    scrollContainer?: any; // Optional container to attach scroll events
    fields?: string[]; // Fields to include in the search query
    check?: any[]; // Optional array for filtering records
    scrollDisabled?: boolean; // Flag to disable scrolling (for pagination)
}

@Injectable({
    providedIn: 'root'
})
export class ListService {

    public isLoading = false; // Flag to indicate if data is being loaded
    public statsData: any; // Variable to store statistics data from API response
    public fromRoot = true; // Flag to indicate if service is used from root (for potential custom logic)
    public scrollDistance = 1; // Distance to trigger scrolling (for infinite scroll)
    public scrollThrottle = 150; // Throttle time for scroll events
    public scrollDisabled = false; // Flag to disable scrolling (for pagination)
    public scrollContainer = '.mat-sidenav-content'; // Default container for scroll events
    limit = 15; // Default limit for number of records per request

    constructor(
        private dataService: DataService
    ) {

    }
/**
     * Initializes the list with the given configuration.
     * @param config Configuration object containing list settings and API details.
     * @param data Initial data to populate the list.
     */
    init(config: Config, data = []) {
        config.skip = config?.skip || 0;
        config.limit = config?.limit || this.limit;
        console.log(config)
        config.self[config.listName] = [];

        this.scrollContainer = config.scrollContainer || '.mat-sidenav-content';
        this.appendResult(config, data);
    }
     /**
     * Appends new results to the existing list.
     * @param config Configuration object.
     * @param list Array of new results to be added.
     * @param retainLimit Optional flag to retain the limit.
     */

    appendResult(config: Config, list: any[], retainLimit?: boolean) {
        if (Array.isArray(list)) {
            config.self[config.listName] = config.skip == 0 ? list : config.self[config.listName].concat(list);

            // How many record need to skip for next time.
            // Enable or disable pagination based on condition.
            if (config.skip !== undefined && config.limit !== undefined) {
                config.skip += list.length;
                config.scrollDisabled = list.length < config.limit;
            }
        } else {
            config.scrollDisabled = true;
        }
    }
 /**
     * Fetches a list of records from the API.
     * @param config Configuration object.
     * @param retainLimit Optional flag to retain the limit.
     * @param from Optional parameter for additional context.
     */
    getList(config: any = {}, retainLimit = true, from?: any) {
        console.log("called            AGAIN")
        console.log(from)
        if (config.self.isSearch) {
            this.getSearchList(config);
            return;
        }

        let limit = config.limit;
        if (retainLimit) {
            config.skip = 0;
            const len = config.self[config.listName]?.length;
            limit = len < config.limit ? config.limit : len;
        }

        this.isLoading = true;
        this.scrollDisabled = true;
        this.dataService.get({
            url: config.url + "skip=" + config.skip + "&limit=" + limit,
            isLoader: false
        }).subscribe((response: any) => {
            this.isLoading = false;
            if (response && response.result) {
                if (typeof config.callback === "function") {
                    config.callback(response.result);
                }
                this.appendResult(config, response.result.records, retainLimit);
            }
            if (response.result.stats) {
                this.statsData = response.result.stats;
            }
        }, () => {
            this.isLoading = false;
        });
    }
/**
     * Sets the API URL for filtering and fetches the list.
     * @param config Configuration object.
     * @param baseURL Base URL for the API.
     * @param filterString Filter query string.
     */
    setFilterApiUrl(config: any, baseURL: string, filterString: string) {
        config.url = baseURL + filterString;
        this.getList(config);
    }
/**
     * Appends search results to the existing list.
     * @param config Configuration object.
     * @param list Array of search results.
     */
    appendSearchResult(config: any, list: any[]) {
        if (Array.isArray(list)) {
            config.self[config.listName] = config.skip == 0 ? list : config.self[config.listName].concat(list);
            // How many record need to skip for next time.
            // Enable or disable pagination based on condition.
            config.skip += list.length;

            config.scrollDisabled = list.length < config.limit;

            if (typeof config.callback === "function") {
                config.callback();
            }
        } else {
            config.scrollDisabled = true;
        }
    }
 /**
     * Fetches a list of search results from the API.
     * @param config Configuration object.
     */
    getSearchList(config: any = {}) {
        if (!config.self.isSearch) {
            this.getList(config);
            return;
        }

        const data = {
            query: {
                keyword: config.self.searchText.replace(/[`~!#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, ''),
                fields: config.fields,
                offset: config.skip,
                limit: config.limit,
                check: config.check || []
            }
        };

        this.isLoading = true;
        this.dataService.post({
            url: config.searchUrl,
            isLoader: false,
            data
        }).subscribe((response: any) => {
            this.isLoading = false;
            if (response && response.result) {
                this.appendSearchResult(config, Array.isArray(response.result.records) ? response.result.records : response.result);
            }
        }, () => {
            this.isLoading = false;
        });
    }
/**
     * Initiates a search operation.
     * @param config Configuration object.
     */
    search(config: any = {}) {
        if (config.self.searchText) {
            config.self.isSearch = true;
            config.skip = config.skip = 0;
            config.limit = config.limit = this.limit;
            this.getSearchList(config);
        } else if (config.self.searchText === '') {
            this.clearSearch(config);
        }
    }
/**
     * Clears the search and fetches the full list.
     * @param config Configuration object.
     */
    clearSearch(config: any = {}) {
        config.self.searchText = '';
        config.self.isSearch = false;
        config.skip = config.skip = 0;
        config.limit = config.limit = this.limit;
        this.getList(config);
    }
}
