

import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { trigger, transition, style, animate, query } from '@angular/animations';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { ApiUrls } from 'src/app/shared/constants/apiUrl.constants';
import { filter } from 'rxjs/operators';


export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(':enter',
      [
        style({ opacity: 0 })
      ],
      { optional: true }
    ),

    query(':leave',
      [
        style({ opacity: 1 }),
        animate('0.2s', style({ opacity: 0 }))
      ],
      { optional: true }
    ),

    query(':enter',
      [
        style({ opacity: 0 }),
        animate('0.2s', style({ opacity: 1 }))
      ],
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],

})

export class LayoutComponent implements OnInit {
  isBottomBarVisible = true; // Flag to control the visibility of the bottom bar
  scrollTimeout: any; // Timeout reference for controlling bottom bar visibility on scroll

  isActive: boolean = true; // Flag for toggling animation state
  reportData: any; // Data for reports (currently unused)
  isLoaded: boolean = false; // Flag to indicate if data is loaded
  userDetail: any; // Details of the logged-in user
  systemStatus: string = ''; // Status of the system (currently unused)
  currentRoute: string = ''; // Current active route
  // Array of menu items
  menus = [{
    icon: 'icon-Home',
    name: 'Home',
    path: '/main/home'
  }, {
    icon: 'icon-Reports',
    name: 'Reports',
    path: '/main/report'
  }, {
    icon: 'icon-Alerts',
    name: 'Alerts',
    path: '/main/alert'
  }, {
    icon: 'icon-System_1',
    name: 'System',
    path: '/main/system-connectivity'
  }];

  
  constructor(
    private dialogService: DialogService, // Service for handling dialogs
    public activatedRoute: ActivatedRoute, // Angular service for accessing route parameters
    private router: Router, // Angular service for navigation
    private dataService: DataService, // Service for making API requests
    private nav: NavigationService, // Service for handling navigation
    private auth: AuthService, // Service for authentication
    private sharedData: SharedDataService // Service for shared data across components
  ) {
// Attach a scroll event listener to handle bottom bar visibility
    window.addEventListener('scroll', this.onWindowScroll, true);
  }
   /**
   * Handles scroll events to hide and show the bottom bar.
   */
  onWindowScroll = () => {
    clearTimeout(this.scrollTimeout); // Clear previous timeout
    this.isBottomBarVisible = false; // Hide bottom bar on scroll

    // Set a timeout to show the bottom bar after scrolling stops
    this.scrollTimeout = setTimeout(() => {
      this.isBottomBarVisible = true;
    }, 500); // Adjust the delay as needed
  }
  /**
   * Removes the scroll event listener when the component is destroyed.
   */
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onWindowScroll, true);
  }
  activeIndex: number = 0; // Index of the currently active menu item
  activeMenu: any = null; // The currently active menu item

  ngOnInit() {
    console.log(this.router.url);
    
    this.userDetail = this.auth.getUserDetail();
    const storedIndex = localStorage.getItem('activeIndex');
    const storedPageUrl = this.router.url;
    console.log(storedIndex)
   
      this.activeIndex = 0;
      if(storedPageUrl=='/main/home' || storedPageUrl=='/main/home/components'){
        this.activeIndex =0
      }else if(storedPageUrl=='/main/system-connectivity' || storedPageUrl=='/main/system-connectivity/components'){
        this.activeIndex =3
        
      }else if(storedPageUrl=='/main/report' || storedPageUrl=='/main/report/components'){
        this.activeIndex =1
        
      }
else if(storedPageUrl=='/main/alert' || storedPageUrl=='/main/alert/components'){
  this.activeIndex =2


      }else{
   
      }
    
  // Subscribe to router events to update the active menu on route change
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        console.log('Current route:', event.url);
        this.currentRoute = event.url;
        this.activeMenu = this.getActiveMenu(); // Update active menu on route change
        const activeMenuIndex = this.menus.findIndex(menu => menu.path === this.activeMenu?.path);
        if(activeMenuIndex>=0 &&activeMenuIndex<=3){
          this.activeIndex = activeMenuIndex; // Update the activeIndex when a menu is clicked
        
       
        if(event.url=='/main/home' || event.url=='/main/home/components'){
          localStorage.setItem('activeIndex', this.activeIndex.toString());
          localStorage.setItem('activePageUrl',event.url.toString());
        }else if(event.url=='/main/system-connectivity' || event.url=='/main/system-connectivity/components'){
          localStorage.setItem('activeIndex', this.activeIndex.toString());
          localStorage.setItem('activePageUrl',event.url.toString());
        }else if(event.url=='/main/report' || event.url=='/main/report/components'){
          localStorage.setItem('activeIndex', this.activeIndex.toString());
          localStorage.setItem('activePageUrl',event.url.toString());
        }
else if(event.url=='/main/alert' || event.url=='/main/alert/components'){
  localStorage.setItem('activeIndex', this.activeIndex.toString());
  localStorage.setItem('activePageUrl',event.url.toString());

        }else{
          localStorage.setItem('activeIndex', this.activeIndex.toString());
          localStorage.setItem('activePageUrl',event.url.toString());
        }
        }
    

      });

  }
   /**
   * Finds and returns the menu item that matches the current route.
   * @returns The active menu item or undefined
   */
  getActiveMenu() {
    return this.menus.find(menu => this.currentRoute.includes(menu.path));
  }

/**
   * Navigates to the selected menu item and updates the active index.
   * @param menu The selected menu item
   * @param index The index of the selected menu item
   */
  goToMenu(menu: any, index: number) {
    this.activeIndex = index;
    localStorage.setItem('activeIndex', this.activeIndex.toString());
    if (menu.path) {
      this.nav.navigateTo([menu.path]);
    }
  }
   /**
   * Toggles the animation state.
   */
  toggleAnimation() {
    this.isActive = !this.isActive;
  }


}