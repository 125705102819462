import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
    providedIn: 'root'
})

export class LoadingService {

    constructor(private ngxLoader: NgxUiLoaderService) { }

    start(name?: string, from?: string) {
        // console.log('start', name, from, Date.now());
        if (name) {
            this.ngxLoader.startLoader(name);
        } else {
            this.ngxLoader.start(); // start master loader
        }
    }

    stop(name?: string, from?: string) {
        // console.log('stop', name, from, Date.now());
        if (name) {
            this.ngxLoader.stopLoader(name);
        } else {
            this.ngxLoader.stop(); // start master loader
        }
    }
}
