import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {

    snackBar: MatSnackBar;

    constructor(private _snackBar: MatSnackBar) {
        this.snackBar = _snackBar;
    }

    public toast(message: string, action: string = 'Close', duration: number = 10000) {
        this.snackBar.open(message, 'Close', { duration: duration });
    }

}
