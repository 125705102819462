
export enum ApiUrls {
  Login = 'greyter-customer/auth/login',
  Refresh_Token = 'business/user/auth/refresh',
  SignUp = 'greyter-customer/auth/register',
  Email_OTP = "greyter-customer/auth/email/resend",
  Email_Verification = 'greyter-customer/auth/email/verify',
  Forget_Password = 'greyter-customer/auth/password/email',
  Password_Reset = 'greyter-customer/auth/password/reset',
  Admin_Password_Change = 'greyter-customer/auth/password/change',

  Application_Constant = 'user/application-constant',
  Add_System = 'greyter-customer/add-system',
  System_Note = 'greyter-customer/system/notes',
  Timezone_List = 'greyter-customer/timezone?limit=unlimited&order_by=region,asc',
  User_Logout = 'greyter-customer/auth/logout',
  Profile_Update = 'greyter-customer/user',
  Base64_Upload = 'greyter-customer/upload/base64',
  Global_settings = 'greyter-customer/user-setting',

  //systems
  Attached_System = 'greyter-customer/user-attached-systems',
  Update_system = 'greyter-customer/system',
  Remove_System = 'greyter-customer/remove-system/',


  //home
  Dashbord_Report = 'greyter-customer/dashboard/trends',

  //alerts
  Activity_Alert = 'greyter-customer/system-active-alerts',
  //user invites
  Invite = 'greyter-customer/invite',
  Invite_remove = 'greyter-customer/invite-remove',
  Invites = 'greyter-customer/invites',
  Invite_resend = 'greyter-customer/invite-resend',



  //support
  Support_Request = 'greyter-customer/support-request',
  Delete_Account = 'greyter-customer/release-systems-and-delete-account'
}
